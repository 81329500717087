import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';

import { usePageContext } from '../../providers/PageProvider';
import { MainContent } from './styles/MainContent';
import { CheckBox } from '@components/form-elements/CheckBox';
import { CustomTable } from '@components/Table';
import { SubscriptionsListDto } from '../../../../../API';
import { TrashBin } from '@components/icons/TrashBin';
import { DeleteButton } from '@pages/User/NotificationSettingsPage/components/Content/styles/DeleteButton';

export const Content = () => {
  const { isLoading, settings, updateSettings, subscriptions, deleteSubscription } = usePageContext();

  const toggleSettings = (name: string) => {
    updateSettings({ ...settings, [name]: !settings[name] });
  };

  if (!settings) {
    return <LinearProgressLoader active={isLoading} />;
  }

  return (
    <>
      <LinearProgressLoader active={isLoading} />
      <MainContent>
        <CustomTable
          className="table"
          data={[
            { type: 'jobAssigned', name: 'Job Assigned' },
            { type: 'repositoryAssigned', name: 'Repository Assigned' },
            { type: 'jobSubscriptions', name: 'Job Watch Subscriptions' },
          ]}
          head={[
            {
              headComponent: () => <td>TYPE</td>,
              render: (data: any) => <p key={data.type}>{data.name}</p>,
            },
            {
              headComponent: () => (
                <td>
                  <div className="items-center flex flex-col justify-center">
                    <div className="label">Email</div>
                    <CheckBox
                      className="checkbox"
                      checked={settings.emailNotifications === undefined ? false : settings.emailNotifications}
                      onChange={() => toggleSettings('emailNotifications')}
                    />
                  </div>
                </td>
              ),
              render: (data: any) => (
                <div className="flex items-center justify-center" key={data.type}>
                  <CheckBox
                    className="switch"
                    disabled={settings.emailNotifications === undefined ? false : !settings.emailNotifications}
                    checked={settings[`${data.type}Email`] === undefined ? false : settings[`${data.type}Email`]}
                    onChange={() => toggleSettings(`${data.type}Email`)}
                  />
                </div>
              ),
            },
            {
              headComponent: () => (
                <td>
                  <div className="items-center flex flex-col justify-center">
                    <div className="label">Push</div>
                    <CheckBox
                      className="checkbox"
                      checked={settings.pushNotifications === undefined ? false : settings.pushNotifications}
                      onChange={() => toggleSettings('pushNotifications')}
                    />
                  </div>
                </td>
              ),
              render: (data: any) => (
                <div className="flex items-center justify-center" key={data.type}>
                  <CheckBox
                    className="switch"
                    disabled={settings.pushNotifications === undefined ? false : !settings.pushNotifications}
                    checked={settings[`${data.type}Push`] === undefined ? false : settings[`${data.type}Push`]}
                    onChange={() => toggleSettings(`${data.type}Push`)}
                  />
                </div>
              ),
            },
          ]}
        />

        <h3>Notification subscriptions:</h3>
        <CustomTable
          className="table"
          data={subscriptions}
          head={[
            {
              headComponent: () => <td>Device</td>,
              render: (data: SubscriptionsListDto) => (
                <div key={data.id}>
                  {data.browserName || data.id}
                  {data.browserVersion ? ` v${data.browserVersion}` : ''} (
                  {data.osName || data.deviceName || 'Unknown device'})
                </div>
              ),
            },
            {
              headComponent: () => <td></td>,
              render: (data: SubscriptionsListDto) => (
                <div key={data.id}>
                  <DeleteButton
                    onClick={() => {
                      deleteSubscription(data.id);
                    }}
                    disabled={isLoading}
                  >
                    <TrashBin />
                  </DeleteButton>
                </div>
              ),
            },
          ]}
        />
      </MainContent>
    </>
  );
};
