import styled from 'styled-components';
import { TUser } from 'app/types/entities/TUser';
import { getAmericanTime } from '@helpers/getAmericanTimezone';
import { fakeDownloadClick, handleOpenDesktop } from '@helpers/jobLinkHelpers';
import { client } from 'app/http';
import sanitize from 'sanitize-filename';
import { Link as RouterLink } from 'react-router-dom';
import { routes } from '@routes';
import { useAuthContext } from '@providers/AuthProvider';
import { Desktop as DesktopIcon } from '@components/icons/Desktop';

const Wrapper = styled.div`
  padding: 16px 0 20px;
  position: relative;
  display: flex;
  gap: 30px;

  .whiteInput {
    input {
      background: white !important;
    }
  }
`;

const LeftSide = styled.div`
  width: 230px;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #878787;
  flex: 0 0 auto;

  h3 {
    margin: 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #858dbd;
    padding: 0 0 10px;
  }
`;

const ColumnTitle = styled.div`
  width: 230px;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #878787;
  flex: 0 0 auto;
  text-transform: uppercase;

  h3 {
    margin: 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #858dbd;
    padding: 0 0 10px;
  }
`;

const RightSide = styled.div`
  display: flex;
  gap: 9px;
  flex-wrap: wrap;
`;

const JobName = styled.div`
  color: #00122d;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  margin: 10px 0 0;

  a {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #00122d;
  }
`;

const StyledLink = styled(RouterLink)`
  color: #00122d;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  text-decoration: underline;

  &:hover {
    color: #40608f;
  }
`;

const IdField = styled.p`
  margin: 0 0 3px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 160%;
  color: #b4b9d9;
`;

const StyledDesktopButtonIconLink = styled.a`
  width: auto;
  padding: 0 5px;
  height: 30px;
  align-self: center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  flex: 0 0 auto;
  gap: 10px;
  color: #858dbd;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;

  :hover {
    background: #cadcf8;

    path {
      fill: #fff;
      color: #fff;
    }
  }
`;

type TProps = {
  user: TUser;
};

export const JobHistory = ({ user }: TProps) => {
  const { organization, me, setWorkspace } = useAuthContext();

  if (!user) {
    return null;
  }

  return (
    <>
      <Wrapper>
        <LeftSide>
          <h3>Job history</h3>
        </LeftSide>

        <RightSide>
          <div className="flex w-full justify-between">
            <ColumnTitle>
              <h3>Job title</h3>
            </ColumnTitle>

            <ColumnTitle>
              <h3>Date completed</h3>
            </ColumnTitle>

            <ColumnTitle>
              <h3>Transcript</h3>
            </ColumnTitle>
          </div>

          <div className="w-full">
            {user.jobs.map((data) => (
              <div key={data.jobId} className="flex items-center justify-between">
                <div style={{ width: '230px' }}>
                  <JobName>
                    <StyledLink
                      to={routes.jobDetails.make(organization?.slug ?? '', data.workspaceSlug, data.jobId)}
                      onClick={() => {
                        const workspace = me.workspaces.find(
                          (ws) => ws.organizationId === organization?.id && data.workspaceId === ws.id,
                        );
                        if (workspace) {
                          setWorkspace(workspace);
                        }
                      }}
                    >
                      {data.title}
                    </StyledLink>
                  </JobName>
                  <IdField>{data.jobId}</IdField>
                </div>

                <div key={data.jobId} style={{ width: '230px' }}>
                  {data.completed ? getAmericanTime(data.completed) : '-'}
                </div>

                <div className="flex" key={data.jobId} style={{ width: '230px', color: '#40608F' }}>
                  <div
                    className="cursor-pointer underline flex"
                    onClick={async () => {
                      const fileName = sanitize(data.title);
                      await client
                        .get(`${window.location.origin}/api/media/${data.jobId}/versions/${data.versionId}`, {
                          responseType: 'blob',
                        })
                        .then(fakeDownloadClick(`${fileName}.docx`));
                    }}
                  >
                    Download
                  </div>
                  <StyledDesktopButtonIconLink
                    target="_blank"
                    rel="noreferrer"
                    className="ml-8"
                    onClick={() => handleOpenDesktop(data?.jobId || '', data.versionId)}
                  >
                    <DesktopIcon />
                  </StyledDesktopButtonIconLink>
                </div>
              </div>
            ))}
          </div>
        </RightSide>
      </Wrapper>
    </>
  );
};
