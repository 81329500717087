import { createContext, useContext, useEffect, useState } from 'react';

import { TPageProviderProps } from 'app/types/providers/TPageProviderProps';

import { NotificationsService, SubscriptionsListDto, UpdateNotificationSettingsDto } from 'app/API';
import { useAPI } from '@hooks/useAPI';

type TState = {
  settings: any;
  subscriptions: SubscriptionsListDto[];
  updateSettings: (v: any) => void;
  deleteSubscription: (v: string) => void;
  isLoading: boolean;
};

const PageContext = createContext<TState>({
  settings: {},
  subscriptions: [],
  updateSettings: () => {},
  deleteSubscription: () => {},
  isLoading: false,
});

export const PageProvider = ({ children }: TPageProviderProps) => {
  const { call } = useAPI();
  const [isLoading, setIsLoading] = useState(false);
  const [settings, setSettings] = useState<any>({});
  const [subscriptions, setSubscriptions] = useState<SubscriptionsListDto[]>([]);

  const fetch = async () => {
    setIsLoading(true);
    const data = await call(NotificationsService.getNotificationSettings());
    setSettings(data);
    const subscriptions = await call(NotificationsService.getSubscriptions());
    setSubscriptions(subscriptions);
    setIsLoading(false);
  };

  const updateSettings = async (settings: UpdateNotificationSettingsDto) => {
    setIsLoading(true);
    const data = await call(NotificationsService.updateNotificationSettings({ requestBody: settings }));
    setSettings(data);
    setIsLoading(false);
  };

  const deleteSubscription = async (id: string) => {
    setIsLoading(true);
    await call(NotificationsService.deleteSubscription({ id }));
    fetch();
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <PageContext.Provider
      value={{
        settings,
        updateSettings,
        isLoading,
        subscriptions,
        deleteSubscription,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);
