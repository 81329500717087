import styled from 'styled-components';

export const MainContent = styled.main`
  padding: 20px 30px 50px;
  min-height: calc(100vh - 120px);
  display: flex;
  gap: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: column;

  .table {
    width: 100%;
    td:first-child {
      width: 100%;
    }
    td {
      width: 100px;
      font-size: 12px;
      font-weight: 500;
      line-height: 30px;
      text-align: left;
      color: #858dbd;
      padding: 10px 0;
    }
  }
`;
