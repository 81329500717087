import { useEffect } from 'react';

import { useWebsocketContext } from '@providers/WebsocketProvider';
import { TPaginatedResponse } from 'app/types/API/TPaginatedResponse';
import { TJob } from 'app/types/entities/TJob';

type TProps = {
  jobs: TPaginatedResponse<TJob>;
  reload: () => void;
};

export const WSUpdateReceiver = ({ jobs, reload }: TProps) => {
  const { jobId } = useWebsocketContext();

  useEffect(() => {
    if (!jobId) {
      return;
    }
    const jobIndex = jobs.data.findIndex((job) => job.id === jobId);

    if (jobIndex > -1) {
      reload();
    }
  }, [jobId]);

  return null;
};
